<template>
  <div id="newsDetail">
    <app-navbar></app-navbar>
    <div class="red-block"></div>
    <div class="whiteBalls">
      <img class="img-fluid" src="@/assets/whiteBalls.png" alt="whiteBalls">
    </div>
    <div class="crossBallone">
      <img class="img-fluid" src="@/assets/crossRedBallone.png" alt="whiteBallone">
    </div>
    <div class="redBallone">
      <img class="img-fluid" src="@/assets/redBallone.png" alt="redBallone">
    </div>
    <div class="container news-block paddingBottom">
      <div class="col-lg-8 offset-lg-2 crop-img">
        <img class="img-fluid img-news-top gallery-picture" v-on:click="$refs.galleryModal.show(getImageUrl())" :src="getImageUrl()" alt="interpret">
      </div>
      <div class="col-lg-8 offset-lg-2 white-box pt-lg-4">
        <a>{{ article.created | date }}</a>
        <h2>{{ article.title | translation }}</h2>
        <div v-html="html" class="textInterpret pb-lg-4 text-justify"></div>
      </div>
      <div class="col-lg-8 offset-lg-2 withOutPadding">
        <div class="row no-gutters">
          <div class="col-lg-4 withOutPadding" v-for="image in images" v-bind:key="image">
            <img class="img-fluid gallery-picture" v-on:click="$refs.galleryModal.show(image)" :src="image" alt="interpret">
          </div>
        </div>
      </div>
      <gallery-modal ref="galleryModal"/>
    </div>
    <app-footer></app-footer>
  </div>
</template>

<script>
import navbar from "@/components/base/navbar";
import axios from "@/api/axiom-instance";
import config from "@/config";
import footer from "@/components/base/footer";
import GalleryModal from "@/components/base/GalleryModal";

export default {
  name: "NewsDetail",
  components: {
    'app-navbar': navbar,
    'app-footer': footer,
    'gallery-modal': GalleryModal
  },
  computed: {
    html: function () {
      return this.$options.filters.translation(this.article.text)
    }
  },
  data() {
    return {
      article: {},
      images: []
    }
  },
  created() {
    if (this.$route.params.id != null) {
      this.load(this.$route.params.id)
    }

  },
  methods: {
    load: function (id) {
      axios
          .get("/article", {params: {id: id}})
          .then((success) => {
            this.article = success.data;
          })
      axios
          .get("/image/list", {params: {type: "article-others/" + id}})
          .then((success) => {
            this.images = success.data.map(it => (config.API_URL + '/image/preview?type=' + it.type + '&name=' + it.name))
          })
    },
    getImageUrl: function () {
      return this.article.id ? (config.API_URL + '/image/preview?type=article-main&name=' + this.article.id) : ""
    }
  }
}
</script>

<style scoped>
h1 {
  font-size: 3.5em;
  color: #152326;
  font-family: "Transcript Pro";
}

h2 {
  color: #0f2328;
  font-size: 2.5em;
  font-family: "Transcript Pro";
}

h3 {
  font-family: "Transcript Pro";
  color: white;
}

a {
  color: #152326;
  text-decoration: none;
  font-size: 1.5em;
  font-family: 'Transcript Pro';
}

a:hover {
  text-decoration: underline;
}

p {
  font-family: "Transcript Mono";
  font-size: 1.2em;
  color: white;
}

hr {
  padding-top: 1em;
  border-top: solid 1px #152326;
}

.crop-img {
  padding: 0 0;
  overflow: hidden;
  height: auto;
}
.img-news-top {
  width: 100%;
}

.withOutPadding {
  padding: 0 0;
}

.textInterpret {
  font-family: "Transcript Mono";
  color: #152326;
  padding-top: 1em;
}

.paddingBottom {
  padding-bottom: 10em;
}

.news-block {
  padding-top: 20em;
}

.white-box {
  background-color: #eae7d6;
}

.crossBallone {
  width:10%;
  position: absolute;
  top: 70vh;
  text-align: end;
  right: 7%;
}

.red-block{
  position: absolute;
  background-color: #ED5B2D;
  top: 105vh;
  width: 70%;
  z-index: -1;
  height: 69vh;
}

.redBallone {
  position: absolute;
  width: 20%;
  top: 23vh;
  left: 15%;
}

.whiteBalls {
  position: absolute;
  width: 6%;
  top: 92vh;
  left: 7%;
}

#newsDetail {
  background-color: #FFCE6D;
  height: 100vh;
}
</style>
